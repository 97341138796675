<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.electricProblemReport')">
      <form v-on:submit.prevent="getElectricReport">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
            {{ $t('message.machineIdFarm') }} :
            <br>
            <multi-list-select
              :list="machineData"
              option-value="machineId"
              option-text="name"
              :selected-items="selectedMachine"
              placeholder="All"
              @select="onSelectMachine"
            ></multi-list-select>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3 my-1">
            {{ $t('message.deviceId') }} :
            <br>
            <b-form-input v-model="searchParams.deviceId"/>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t('message.selectMonth') }} :
            <br>
            <vue-monthly-picker
              v-model="selectedMonth"
              :monthLabels="language == 'th' ? monthsTH : monthsEN "
              dateFormat="MM/YYYY"
            ></vue-monthly-picker>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            <br>
            <b-btn variant="primary" type="submit" class="col-sm-12 col-md-6 ">
              <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
            </b-btn>&nbsp;&nbsp;&nbsp;
            <!-- <b-btn variant="danger" @click="newReport()">
              <i class="fa fa-plus-square"></i>&nbsp;{{ $t('message.create') }}
            </b-btn> -->
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align: center">
            <tr>
              <th>{{ $t('message.machineIdFarm') }}</th>
              <th>{{ $t('message.timeStart') }}</th>
              <th>{{ $t('message.timeSolve') }}</th>
              <th>{{ $t('message.problemTime') }} ({{ $t('message.hour') }})</th>
              <th>{{ $t('message.errorDevice') }}</th>
              <th>{{ $t('message.createBy') }}</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody style="text-align: center">
            <tr v-for="item in rowData">
              <td>{{item.machineId}}</td>
              <td>{{item.startTime | moment("YYYY-MM-DD HH:mm")}}</td>
              <td>{{item.endTime | moment("YYYY-MM-DD HH:mm")}}</td>
              <td>{{item.errorTime }}</td>
              <td>{{item.errorDevice }}</td>
              <td>{{item.createBy}}</td>
              <td>
                <b-btn variant="warning" @click="editReport(item)" size="sm">{{ $t('message.editOrUpdate') }}</b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-modal
      ref="editModal"
      @ok="updateReport()"
      @cancel="getElectricReport()"
      :ok-title="$t('message.save')"
      :cancel-title="$t('message.cancel')"
      :title="$t('message.editOrUpdate')"
    >
      <table class="table-modal">
        <tr>
          <td>{{ $t('message.machineIdFarm') }}</td>
          <td>{{modalData.machineId}}</td>
        </tr>
        <tr>
          <td>{{ $t('message.timeStart') }}</td>
          <td>
            <date-picker
              type="datetime"
              v-model="modalData.startTime"
              lang="en"
              format="yyyy-MM-dd HH:mm:ss"
            ></date-picker>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.timeSolve') }}</td>
          <td>
            <date-picker
              type="datetime"
              v-model="modalData.endTime"
              lang="en"
              format="yyyy-MM-dd HH:mm:ss"
            ></date-picker>
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal
      ref="createModal"
      @ok="createReport()"
      @cancel="getElectricReport()"
      :ok-title="$t('message.create')"
      :cancel-title="$t('message.cancel')"
      :title="$t('message.createReport')"
      size="lg"
    >
      <table class="table-modal">
        <tr>
          <td>{{ $t('message.machineIdFarm') }}</td>
          <td>
            <model-list-select
              :list="machineData"
              v-model="newReportData.machineId"
              option-value="machineId"
              option-text="name"
              :placeholder="$t('message.machineIdFarm')"
            ></model-list-select>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.errorDevice') }} (option)</td>
          <td>
            <b-form-input type="number" v-model.number="newReportData.errorDevice"></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.timeStart') }}</td>
          <td>
            <date-picker
              type="datetime"
              v-model="newReportData.startTime"
              lang="en"
              format="yyyy-MM-dd HH:mm:ss"
            ></date-picker>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.timeSolve') }}</td>
          <td>
            <date-picker
              type="datetime"
              v-model="newReportData.endTime"
              lang="en"
              format="yyyy-MM-dd HH:mm:ss"
            ></date-picker>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>


<script>
import moment from "moment";
import webServices from "../../script";
import VueMonthlyPicker from "vue-monthly-picker";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ModelListSelect,
  MultiListSelect
} from "../../custom_modules/search-select";
export default {
  name: "Reports",
  components: {
    MultiListSelect,
    ModelListSelect,
    VueMonthlyPicker,
    DatePicker
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      rowData: [],
      modalData: {},
      machineData: [],
      searchParams: {},
      newReportData: {},
      selectedMachine: [],
      status: [
        { value: "SUCCESS", text: "แก้ไขแล้ว" },
        { value: "PROCESSING", text: "กำลังดำเนินการ" },
        { value: "RECEIVED", text: "ได้รับแจ้งปัญหาแล้ว" }
      ],
      selectedMonth: moment(new Date()),
      language: window.localStorage.getItem("language"),
      monthsTH: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      monthsEN: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    };
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    changeLanguage(index) {
      if (index === 0) {
        this.$i18n.locale = "th";
        this.flag = `flag-icon flag-icon-th`
        window.localStorage.setItem('language', 'th')

      } else if (index === 1) {
        this.$i18n.locale = "gb";
        this.flag = `flag-icon flag-icon-gb`
        window.localStorage.setItem('language', 'gb')
      }
      this.$store.state.nav = this.$t("message.nav")
    },

    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.$Progress.finish();
          this.machineData = res.data;
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchParams.machineId = this.selectedMachine.map(
        data => data.machineId
      );
    },
    getElectricReport() {
      this.$Progress.start();
      this.searchParams.month = new Date(this.selectedMonth);

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchParams.userName = localStorageUser.userName
      this.searchParams.userRole = localStorageUser.role
      this.searchParams.userType = localStorageUser.type
      this.searchParams.userConfiglanguage = localStorageUser.config.language
      this.searchParams.datetimeRequest = datetimeRequest

      webServices
        .getElectricityProblemReport(this.searchParams)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            this.rowData = res.data.reportData;
          } else {
            this.$Progress.fail();
            console.log(err);
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
        });
    },
    editReport(data) {
      this.modalData = data;
      this.$refs.editModal.show();
    },
    newReport() {
      this.$refs.createModal.show();
    },
    updateReport() {
      delete this.modalData.createdAt;
      delete this.modalData.updatedAt;
      console.log(this.modalData);
      webServices
        .uploadElectricityProblemReport(this.modalData)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.modalData = {};
          } else {
            this.$Progress.fail();
            console.log(err);
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
          this.getElectricReport();
        })
        .catch(err => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
          this.getElectricReport();
        });
    },
    createReport() {
      webServices
        .createElectricityProblemReport(this.newReportData)
        .then(res => {
          console.log(res.data);
          if (res.data.success) {
            this.$Progress.finish();
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.modalData = {};
          } else {
            this.$Progress.fail();
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
          this.newReportData = {};
          this.getElectricReport();
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText)
          });
          this.getElectricReport();
        });
    }
  },
  mounted() {
    this.getMachine();
    this.getElectricReport();
  }
};
</script>